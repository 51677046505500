import { FC } from 'react';
import Image from 'next/image';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body, spacing } from '@sumup/circuit-ui';

import { QuantitySelector } from './QuantitySelector';

import { IProduct } from 'productSelection/types/products';
import { getResizedUrl } from 'shared/services/imageUrl';

const DEFAULT_IMAGE_SIZE = 128;

const ListItem = styled('li')<{ usedForCart?: boolean }>(
  ({ theme, usedForCart }) => css`
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    ${usedForCart &&
    `
      background-color: var(--cui-bg-subtle);
      padding: ${theme.spacings.mega};
      border-radius: ${theme.spacings.mega};
    `}
  `,
);

const TextContainer = styled('div')(
  () => css`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
);

const ImageWrapper = styled('div')(
  ({ theme }) => css`
    max-width: 64px;
    height: 64px;
    margin-right: ${theme.spacings.kilo};
    background: var(--cui-bg-normal);
    border-radius: ${theme.spacings.byte};
  `,
);

const CustomMarginText = styled(Body)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacings.byte};
  `,
);

const QuantityPriceContainer = styled('div')(
  () => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
);

const StyledBolded = styled.b`
  font-weight: bold; // defaults to font-weight: inherit
`;

export interface ProductListItemProps extends IProduct {
  className?: string;
  quantityText: string;
  isQuantitySelectionEnabled?: boolean;
  onChangeQuantity: (quantity: number) => void;
  usedForCart?: boolean;
  quantityLimit?: number;
  formattedInstallmentAmount?: string;
  imgHydration?: 'lazy' | 'eager';
  canEmptyCart: boolean;
  numberOfProducts: number;
}

const InstallmentContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

/**
 * The product list item used to render the product
 * information on theOrderOverview component.
 */
export const ProductListItem: FC<ProductListItemProps> = ({
  quantityText,
  name,
  quantity,
  formattedUnitAmount,
  productContent: { imageSrc, imageAltText },
  isQuantitySelectionEnabled,
  usedForCart = false,
  onChangeQuantity,
  className,
  numberOfInstallments,
  formattedInstallmentAmount,
  quantityLimit,
  imgHydration = 'lazy',
  canEmptyCart = false,
  numberOfProducts,
}: ProductListItemProps) => {
  const hasInstallments = numberOfInstallments > 1;

  return (
    <ListItem className={className} usedForCart={usedForCart}>
      <ImageWrapper>
        <Image
          src={getResizedUrl(imageSrc, DEFAULT_IMAGE_SIZE, DEFAULT_IMAGE_SIZE)}
          alt={imageAltText}
          width={DEFAULT_IMAGE_SIZE}
          height={DEFAULT_IMAGE_SIZE}
          loading={imgHydration}
        />
      </ImageWrapper>

      <TextContainer>
        <CustomMarginText variant="highlight">{name}</CustomMarginText>
        <QuantityPriceContainer>
          {isQuantitySelectionEnabled ? (
            <QuantitySelector
              onChange={onChangeQuantity}
              quantity={quantity}
              quantityLimit={quantityLimit}
              canEmptyCart={canEmptyCart}
              numberOfProducts={numberOfProducts}
            />
          ) : (
            <Body css={spacing({ bottom: 0 })}>
              {quantityText}: &nbsp;
              <StyledBolded>{quantity}</StyledBolded>
            </Body>
          )}

          {hasInstallments ? (
            <InstallmentContainer>
              <Body size="two" variant="subtle" css={spacing({ right: 'bit' })}>
                {numberOfInstallments}x
              </Body>
              <Body size="one">{formattedInstallmentAmount}</Body>
            </InstallmentContainer>
          ) : (
            <Body>{formattedUnitAmount}</Body>
          )}
        </QuantityPriceContainer>
      </TextContainer>
    </ListItem>
  );
};
