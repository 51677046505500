import { LineItem } from '@commercelayer/sdk';

import { IProduct } from 'productSelection/types/products';
import { ICLayerProduct } from 'shared/store/order/types';
import { formatCurrencyWithLocale } from 'utils/currency';
import { retrieveGlobalLocale } from 'shared/services/OrderInformationService.globals';

export const getProductContentByReference = (
  productsContent: IProduct[],
  reference: LineItem['reference'],
): IProduct => productsContent.find((cp) => cp.id === reference);

export const combineProductsInfo = (
  productsContent: IProduct[],
  productsInCart: ICLayerProduct[],
  locale = retrieveGlobalLocale(),
): IProduct[] =>
  productsInCart.reduce<IProduct[]>((mem, p) => {
    const contentfulProduct = getProductContentByReference(
      productsContent,
      p.reference,
    );

    if (!contentfulProduct) {
      return mem;
    }

    return [
      ...mem,
      {
        id: p.id,
        trackingId: contentfulProduct?.trackingId,
        name: contentfulProduct.name,
        productContent: contentfulProduct.productContent,
        skuCode: p.skuCode,
        sku: contentfulProduct.sku,
        discountCents: p.discountCents,
        discountRate: p.discountRate,
        quantity: p.quantity,
        reference: p.reference,
        slug: contentfulProduct.slug,
        formattedTotalAmount: formatCurrencyWithLocale(p.amountFloat, locale),
        formattedUnitAmount: formatCurrencyWithLocale(
          p.unitAmountFloat,
          locale,
        ),
        unitAmountFloat: p.unitAmountFloat,
        amountFloat: p.amountFloat,
        numberOfInstallments: contentfulProduct.numberOfInstallments,
      },
    ];
  }, []);

export interface IProductTrackingInfo {
  id: LineItem['id'];
  trackingId: IProduct['trackingId'];
  price: LineItem['formatted_total_amount'];
  quantity: LineItem['quantity'];
}

export const getProductsTrackingInfo = (
  productsContent: IProduct[],
  lineItems: LineItem[],
): IProductTrackingInfo[] =>
  lineItems.reduce<IProductTrackingInfo[]>((acc, product) => {
    const contentfulProduct = getProductContentByReference(
      productsContent,
      product.reference,
    );

    if (!contentfulProduct) {
      return acc;
    }

    return [
      ...acc,
      {
        id: product.id,
        trackingId: contentfulProduct?.trackingId,
        price: product.formatted_total_amount,
        quantity: product.quantity,
      },
    ];
  }, []);
