import { useCallback, useMemo } from 'react';
import type { FunctionComponent } from 'react';

import {
  StyledQuantitySelector,
  StyledButton,
  StyledQuantity,
  StyledPlusIcon,
  StyledMinusIcon,
  StyledBinIcon,
} from './QuantitySelector.styles';

import { useInputState } from 'shared/hooks/helpers/useInputState';

export interface IQuantitySelectorProps {
  quantity: number;
  onChange: (quantity: number) => void;
  quantityLimit?: number;
  canEmptyCart?: boolean;
  numberOfProducts: number;
}

const QuantitySelector: FunctionComponent<IQuantitySelectorProps> = ({
  quantity: quantityFromProps,
  onChange,
  quantityLimit,
  canEmptyCart = false,
  numberOfProducts,
}) => {
  const [quantity, setQuantity] = useInputState<number>(quantityFromProps);
  const isSelectionDisabled = quantity === 0;
  const isRemoveButtonDisabled =
    isSelectionDisabled ||
    (!canEmptyCart && quantity === 1 && numberOfProducts === 1);
  const hasReachedQuantityLimit = quantityLimit && quantity >= quantityLimit;
  const canIncreaseQuantity = !isSelectionDisabled && !hasReachedQuantityLimit;
  const quantityToDisplay = useMemo<string>(
    () =>
      String(quantity).length > 1
        ? String(quantity)
        : String(quantity).padStart(2, '0'),
    [quantity],
  );

  const handleChange = useCallback<typeof onChange>(
    (newQuantity) => {
      onChange(newQuantity);
      setQuantity(newQuantity);
    },
    [onChange, setQuantity],
  );
  const removeProduct = useCallback(
    () => handleChange(quantity - 1),
    [handleChange, quantity],
  );
  const addProduct = useCallback(
    () => handleChange(quantity + 1),
    [handleChange, quantity],
  );

  const handleRemoveProduct = () => {
    //If there's more than one "type" of product in the cart
    if (numberOfProducts > 1) {
      removeProduct();
      return;
    }

    //If there's more than one quantity of product in the cart
    //OR canEmptyCart is true
    if (quantity > 1 || canEmptyCart) {
      removeProduct();
    }
  };

  return (
    <StyledQuantitySelector>
      <StyledButton
        data-testid="cart-product-quantity--removeBtn"
        aria-label="-"
        removeButton
        disabled={isRemoveButtonDisabled}
        onClick={handleRemoveProduct}
      >
        {quantity === 1 ? (
          <StyledBinIcon size="16" />
        ) : (
          <StyledMinusIcon size="16" />
        )}
      </StyledButton>
      <StyledQuantity data-testid="cart-product-quantity">
        {quantityToDisplay}
      </StyledQuantity>
      <StyledButton
        data-testid="cart-product-quantity--addBtn"
        aria-label="+"
        addButton
        disabled={!canIncreaseQuantity}
        onClick={addProduct}
      >
        <StyledPlusIcon size="16" />
      </StyledButton>
    </StyledQuantitySelector>
  );
};

export default QuantitySelector;
