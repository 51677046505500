import queryString from 'query-string';

const spaceId = process.env.CONTENTFUL_SPACE;
const contentfulAssetsDomain = process.env.CONTENTFUL_ASSETS_DOMAIN;

export const replaceContentfulImageUrl = (url: string): string =>
  url.replace(`${contentfulAssetsDomain}/${spaceId}`, '/cf-assets');
/**
 * Contentful assets URLs are provided omitting the protocol,
 * which leads the browser to take a decision of loading the image
 * based on the document's protocol.
 * e.g. "//images.ctfassets.net/air_lite.png".
 * However, the Contentful assets aren't accessible under "http"
 * protocol neither redirects to "https". Since the development
 * environment is under "http", we've written this function
 * to guarantee the image will be loaded under "https" protocol
 */
const PROTOCOL_OMITTED_OR_HTTP_REGEX = /^\/\/|http:\/\//;
export const forceImgSrcToHttps = (url: string): string =>
  replaceContentfulImageUrl(
    url.replace(PROTOCOL_OMITTED_OR_HTTP_REGEX, 'https://'),
  );

export const convertImageToPng = (url: string): string => {
  const format = 'png';
  const width = 1000;
  const height = 1000;

  const params = queryString.stringify({
    fm: format,
    w: width,
    h: height,
  });

  return `${forceImgSrcToHttps(url)}?${params}`;
};
