import { FC } from 'react';
import { Subtract } from 'utility-types';

import { useTypedSelector } from 'shared/store';
import { IProduct } from 'productSelection/types/products';
import { combineProductsInfo } from 'shared/services/ProductService';
import { IOrderDetails } from 'shared/store/order/types';
import { BUSINESS_ACCOUNT_PRODUCT } from 'shared/constants/ProductConstants';

// External Props
// withOrderInfo use these props to decide injected props
interface ExternalProps {
  productsContentful: IProduct[];
  largestInstallmentInCart?: number;
}

// Injected Props
interface InjectedOrderInfoProps extends IOrderDetails {
  products: IProduct[];
}

// https://medium.com/@jrwebdev/react-higher-order-component-patterns-in-typescript-42278f7590fb injector pattern
// This removes the injected prop from the prop types of the wrapped component,
// which allows us to require the prop (in this case 'products')
// inside the wrapped component, while also not having to provide them
// in the page/component that is importing the wrapped component
// to keep TypeScript from breaking
// see OrderSummary (wrapped comp) used in ThankYou page as example

export function withOrderInfo<T extends Partial<InjectedOrderInfoProps>>(
  Comp: FC<T>,
): FC<Subtract<T, Partial<InjectedOrderInfoProps>> & ExternalProps> {
  const displayName = Comp.displayName || Comp.name || 'Component';
  const WrappedComponent = (props: T & ExternalProps) => {
    const {
      orderDetails,
      products: productsInCart,
      loading,
    } = useTypedSelector((state) => state.order);
    const { productsContentful } = props;
    const products = combineProductsInfo(productsContentful, productsInCart);

    // Get installments for current order
    const productCLReference = productsInCart.map((p) => p.reference);
    const productInstallments = productsContentful
      .filter((p) => productCLReference.includes(p.id))
      .map((p) => p.numberOfInstallments);
    const largestInstallmentInCart = props.largestInstallmentInCart
      ? props.largestInstallmentInCart
      : Math.max(...productInstallments);

    const hasBusinessAccount = !!productsInCart.find(
      (product) => product.skuCode === BUSINESS_ACCOUNT_PRODUCT.sku,
    );

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error FIXME: https://sumupteam.atlassian.net/browse/ACQJ-721
      <Comp
        {...props}
        {...orderDetails}
        products={products}
        loading={loading}
        largestInstallmentInCart={largestInstallmentInCart}
        hasBusinessAccount={hasBusinessAccount}
      />
    );
  };
  WrappedComponent.displayName = `withOrderInfo_${displayName}`;

  return WrappedComponent;
}
