import { IAddressPayload } from 'shared/store/address/types';
import { formatCurrencyWithLocale } from 'utils/currency';

// https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
// Round half away from zero ('commercial' rounding)
// Uses correction to offset floating-point inaccuracies.
// Works symmetrically for positive and negative numbers.
const roundTwoDigitsCommercial = (amountFloat: number) => {
  const p = 100; // 10 to the power 2, 2 being the two digits
  const n = amountFloat * p * (1 + Number.EPSILON);

  return Math.round(n) / p;
};

export const getPercentageTaxRate = (taxRateFloat: number) => {
  return roundTwoDigitsCommercial(taxRateFloat * 100);
};

export const getTaxAmount = (taxRateFloat: number, amountFloat: number) => {
  const amount = amountFloat * taxRateFloat;

  return roundTwoDigitsCommercial(amount);
};

export const getTaxAmountFormatted = (
  taxRateFloat: number,
  amountFloat: number,
  locale: string,
) => {
  const amount = getTaxAmount(taxRateFloat, amountFloat);

  return formatCurrencyWithLocale(amount, locale);
};

export const getAmountWithTaxes = (
  taxRateFloat: number,
  amountFloat: number,
) => {
  const amount = amountFloat + amountFloat * taxRateFloat;

  return roundTwoDigitsCommercial(amount);
};

export const getAmountWithTaxesFormatted = (
  taxRateFloat: number,
  amountFloat: number,
  locale: string,
) => {
  const amount = getAmountWithTaxes(taxRateFloat, amountFloat);

  return formatCurrencyWithLocale(amount, locale);
};

export type GetOrderTotalAmountFormattedReq = {
  shippingAddress: IAddressPayload;
  defaultTaxRate: number;
  totalAmountFloat: number;
  formattedTotalAmountWithTaxes: string;
  locale: string;
  doFallbackToDefaultTaxRate: boolean;
};

export const getOrderTotalAmountFormatted = (
  req: GetOrderTotalAmountFormattedReq,
) => {
  // Use amount with default tax rate if shipping address
  // is not yet attached.
  if (
    !req.shippingAddress?.id &&
    req.defaultTaxRate > 0 &&
    req.doFallbackToDefaultTaxRate
  ) {
    return getAmountWithTaxesFormatted(
      req.defaultTaxRate,
      req.totalAmountFloat,
      req.locale,
    );
  }

  // Use amount with order tax rate if shipping address
  // attached, meaning tax calculation has taken place for order
  // in CommerceLayer.
  return req.formattedTotalAmountWithTaxes;
};
