import { Plus, Delete, Minus } from '@sumup/icons';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Button } from 'src/experiments/odl/components/Button';

export const StyledQuantitySelector = styled.div`
  display: flex;
`;

type StyledButtonProps = {
  removeButton?: boolean;
  addButton?: boolean;
};
export const StyledButton = styled(Button)<StyledButtonProps>(
  ({ theme, removeButton, addButton }) => css`
    width: ${theme.iconSizes.giga};
    height: ${theme.iconSizes.giga};
    padding: 0;
    border-radius: 0;
    border: 0;
    z-index: 1;
    align-items: center;
    :hover {
      background: var(--cui-bg-normal);

      svg path {
        fill: var(--cui-fg-accent);
      }
    }

    ${removeButton &&
    `
      border-radius: ${theme.spacings.bit} 0 0 ${theme.spacings.bit};
    `};
    ${addButton &&
    `
      border-radius: 0 ${theme.spacings.bit} ${theme.spacings.bit} 0;
    `};
  `,
);

export const StyledQuantity = styled.span`
  ${({ theme }) => `
    line-height: ${theme.iconSizes.giga};
    padding: 0 ${theme.spacings.byte};
    border: 1px solid var(--cui-border-normal);
    border-top: 0;
    border-bottom: 0;
    background-color: var(--cui-bg-normal);
  `}
`;

export const StyledPlusIcon = styled(Plus)`
  path {
    fill: var(--cui-fg-subtle);
  }
`;

export const StyledMinusIcon = styled(Minus)`
  path {
    fill: var(--cui-fg-subtle);
  }
`;

export const StyledBinIcon = styled(Delete)`
  path {
    fill: var(--cui-fg-subtle);
  }
`;
