import { useEffect, useState } from 'react';

/**
 * This small hook wraps the state in between an `controlled <> uncontrolled` component
 * It will always prio taking the value from the user input set by the `onChange` callback
 * But it will take into consideration the value incoming from props as it's initial value or if it's set reactively by an outer data source
 *
 * @param {unknown} inputFromProps The input value coming from props
 * @returns {unknown} The value that the input can use
 */
function useInputState<T>(inputFromProps: T): [T, (v: T) => T] {
  const [valueFromProps, setValueFromProps] = useState(inputFromProps);
  const [value, setValue] = useState(valueFromProps);
  const [isControlled, setIsControlled] = useState(false);

  // set component as uncontrolled and use the value from the change callback (like user input)
  const onChange = (v: T) => {
    setValue(v);
    setIsControlled(false);
    return v;
  };

  // set component as controlled by the values incoming from props
  useEffect(() => {
    if (inputFromProps !== valueFromProps) {
      setValueFromProps(inputFromProps);
      setIsControlled(true);
    }
  }, [valueFromProps, inputFromProps]);

  return [isControlled ? valueFromProps : value, onChange];
}

export default useInputState;
