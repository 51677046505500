import styled from '@emotion/styled';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { Headline, List, spacing, Body, Hr } from '@sumup/circuit-ui';

import {
  ExternalLink,
  InternalLink,
} from 'src/experiments/odl/components/Link';
import { getShopExperienceLink } from 'shared/utils/shop-experience-link';

export const Italic = styled(Body)`
  font-style: italic;
`;

export const deliveryEstimateOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => (
      <Body variant="highlight" as="strong">
        {text}
      </Body>
    ),
    [MARKS.ITALIC]: (text) => <Italic as="p">{text}</Italic>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const href = node.data.uri as string;
      const isExternalLink = href.startsWith('http');

      return (
        <>
          {isExternalLink && (
            <ExternalLink href={href}>{children}</ExternalLink>
          )}
          {!isExternalLink && (
            <InternalLink
              href={{
                pathname: getShopExperienceLink(href),
              }}
            >
              {children}
            </InternalLink>
          )}
        </>
      );
    },
    [BLOCKS.UL_LIST]: (_, children) => (
      <List
        css={spacing({
          bottom: 'byte',
        })}
      >
        {children}
      </List>
    ),
    [BLOCKS.OL_LIST]: (_, children) => (
      <List
        variant="ordered"
        css={spacing({
          bottom: 'byte',
        })}
      >
        {children}
      </List>
    ),
    [BLOCKS.HEADING_2]: (_, children) => (
      <Headline size="two" as="h2" css={spacing({ bottom: 'byte' })}>
        {children}
      </Headline>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <Headline size="three" as="h3" css={spacing({ bottom: 'byte' })}>
        {children}
      </Headline>
    ),
    [BLOCKS.HEADING_4]: (_, children) => (
      <Headline size="four" as="h4" css={spacing({ bottom: 'byte' })}>
        {children}
      </Headline>
    ),
    [BLOCKS.HR]: () => <Hr />,
    [BLOCKS.QUOTE]: (_, children) => (
      <Body variant="quote" as="blockquote">
        {children}
      </Body>
    ),
    [BLOCKS.PARAGRAPH]: (_, children) => <span>{children}</span>,
  },
  renderText: (text: string) => {
    // Please use Shift + Enter to produce linebreak.
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};
