import { getProductDiscountRate } from './discount';

import {
  IDigitalProductDetails,
  IProductDetails,
} from 'productSelection/types/products';
import { Promotion } from 'shared/store/promotions/types';
import { formatCurrencyWithLocale } from 'utils/currency';
import {
  installmentPrice,
  floorDecimal,
} from 'checkout/services/InstallmentService';
import { ISkuPriceData } from 'shared/infra/storefront/markets/prices';

export const applyDefaultTaxRate = (amount: number, taxRate = 0): number =>
  amount * (1 + taxRate / 100.0);

export const getDefaultTaxAmount = (amount: number, taxRate = 0): number =>
  amount * (taxRate / 100.0);

export const getFormattedDefaultTaxAmount = (
  amount: number,
  locale: string,
  taxRate = 0,
): string => {
  const taxAmount = getDefaultTaxAmount(amount, taxRate);
  return formatCurrencyWithLocale(taxAmount, locale);
};

export const getFormattedAmountWithDefaultTaxRate = (
  amount: number,
  locale: string,
  taxRate = 0,
): string => {
  const amountWithDefaultTaxes = applyDefaultTaxRate(amount, taxRate);
  return formatCurrencyWithLocale(amountWithDefaultTaxes, locale);
};

export const getFormattedInstallmentAmount = (
  price: number,
  installment: number,
  locale: string,
): string =>
  formatCurrencyWithLocale(
    floorDecimal(installmentPrice(price, installment)),
    locale,
  );

const getTaxMessage = (
  suffix: string,
  price: ISkuPriceData,
  locale: string,
): string => {
  if (price.hasTax) {
    return `${formatCurrencyWithLocale(
      price.totalAmountWithTaxesFloat,
      locale,
    )} ${suffix}`;
  }
  return '';
};

export interface IProductWithPrices extends IProductDetails {
  couponPromotion: Promotion;
  formattedAmount: string;
  formattedCompareAmount: string;
  discountRate: number;
  compareAtAmountFloat: number;
  formattedInstallmentAmount?: string;
  formattedInstallmentOriginalAmount?: string;
  promotionPurchaseLimit?: number;
  appliedCouponCode?: string;
  stockQuantity: number | null;
  formattedTaxMessage: string;
}

export type IDigitalProductWithoutPrices = Partial<IProductWithPrices>;

export const getProductsWithAllDetails = (
  products: IProductDetails[] | IDigitalProductDetails[],
  prices: Record<string, ISkuPriceData>,
  locale: string,
  taxMessage: string = '',
): IProductWithPrices[] | IDigitalProductWithoutPrices[] =>
  products.map((untypedProduct) => {
    const isDigitalProduct = (untypedProduct as IDigitalProductDetails)
      .linkToDigitalProduct;

    // support digital products - early escape
    if (isDigitalProduct) {
      return {
        amountFloat: 0,
        compareAtAmountFloat: 0,
        formattedAmount: '',
        formattedCompareAmount: '',
        discountRate: 0,
      } as IDigitalProductWithoutPrices;
    }

    const product = untypedProduct as IProductDetails;
    const price = prices[product.id];

    const { appliedCouponCode, promotionPurchaseLimit, stockQuantity } = price;

    return {
      ...product,
      formattedAmount: formatCurrencyWithLocale(price.totalAmountFloat, locale),
      formattedCompareAmount: formatCurrencyWithLocale(
        price.compareAtAmountFloat,
        locale,
      ),
      amountFloat: price.totalAmountFloat,
      compareAtAmountFloat: price.compareAtAmountFloat,
      discountRate: getProductDiscountRate(
        price.compareAtAmountFloat,
        price.totalAmountFloat,
      ),
      formattedInstallmentAmount: getFormattedInstallmentAmount(
        price.totalAmountFloat,
        product.numberOfInstallments,
        locale,
      ),
      formattedInstallmentOriginalAmount: getFormattedInstallmentAmount(
        price.compareAtAmountFloat,
        product.numberOfInstallments,
        locale,
      ),
      promotionPurchaseLimit,
      appliedCouponCode,
      stockQuantity,
      formattedTaxMessage: getTaxMessage(taxMessage, price, locale),
    } as IProductWithPrices;
  });

export const getPromotionQuantityLimitBySku = (
  sku: string | string[],
  promotionDetails: Record<string, Promotion> = {},
): number => {
  if (typeof sku === 'string') {
    return promotionDetails[sku]?.purchaseLimit;
  }

  return promotionDetails[sku[0]]?.purchaseLimit;
};
