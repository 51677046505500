import { FC } from 'react';
import { Headline, Hr, Body, Spinner, spacing } from '@sumup/circuit-ui';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { ProductListItem } from './components/ProductListItem';
import { deliveryEstimateOptions } from './components/deliveryEstimateOptions';

import { IOrderDetails } from 'shared/store/order/types';
import { IUiCartFields } from 'shared/infra/contentful/contentful';
import { IProduct } from 'productSelection/types/products';
import {
  getFormattedInstallmentAmount,
  getPromotionQuantityLimitBySku,
} from 'productSelection/services/product';
import { useTypedSelector } from 'shared/store';
import {
  getOrderTotalAmountFormatted,
  getPercentageTaxRate,
  getTaxAmountFormatted,
} from 'utils/tax';
import { useShopExperience } from 'shared/context';
import { ShopExperience } from 'shared/constants/ShopExperience';
import { getItem } from 'shared/infra/storage';
import {
  SHOW_VAT_AMOUNT_EXPERIMENT,
  VARIATION,
} from 'shared/services/optimizely/constants';

type OrderDetails = Pick<
  IOrderDetails,
  | 'formattedTotalAmount'
  | 'formattedSubtotalAmount'
  | 'formattedTotalTaxAmount'
  | 'taxRate'
  | 'formattedTotalAmountWithTaxes'
  | 'formattedDiscountAmount'
  | 'discountAmountCents'
>;
type OverviewContent = Pick<
  IUiCartFields,
  | 'quantityText'
  | 'subtotalText'
  | 'taxRateText'
  | 'deliveryEstimateText'
  | 'totalText'
  | 'overviewTitleText'
  | 'discountAppliedText'
  | 'freeText'
>;
export interface OrderOverviewProps
  extends OverviewContent,
    Partial<OrderDetails> {
  products?: IProduct[];
  usedInModal?: boolean;
  loading?: boolean;
  usedForCart?: boolean;
  isQuantitySelectionEnabled?: boolean;
  largestInstallmentInCart?: number;
  installmentsOnCheckout?: number;
  installments?: number;
  totalAmountFloat?: number;
  totalAmountWithTaxesFloat?: number;
  noMargin?: boolean;
  imgHydration?: 'lazy' | 'eager';
  canEmptyCart?: boolean;
  onLineItemQuantityChange?: (product: IProduct) => (quantity: number) => void;
  hasBusinessAccount?: boolean;
  isODLExperimentEnabled?: boolean;
  defaultTaxRate: number;
}

const DescriptionContainer = styled('div')<{
  alignItems?: string;
  usedForCart?: boolean;
}>(
  ({ theme, usedForCart, alignItems = 'center' }) => css`
    display: flex;
    flex-direction: row;
    align-items: ${alignItems};
    justify-content: space-between;
    margin-bottom: ${theme.spacings.mega};
    &:last-of-type {
      margin-bottom: 0;
    }

    ${usedForCart &&
    `
      margin-left: ${theme.spacings.mega};
      margin-right: ${theme.spacings.mega};
    `}
  `,
);

const FreeDeliveryText = styled('span')(
  ({ theme }) => css`
    color: var(--cui-border-success);
    font-weight: ${theme.fontWeight.bold};
  `,
);

const TotalPrice = styled('span')(
  ({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
  `,
);

const StyledHr = styled(Hr)`
  border-top: 1px solid var(--cui-border-subtle);
`;

const LoadingContainer = styled.div(
  () => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
  `,
);

const StyledProductListItem = styled(ProductListItem)<{
  usedForCart?: boolean;
  isQuantitySelectionEnabled?: boolean;
}>(
  ({ theme, usedForCart, isQuantitySelectionEnabled }) => `
    margin-bottom: ${theme.spacings.mega};
    :last-child {
      margin-bottom: ${
        isQuantitySelectionEnabled && usedForCart ? theme.spacings.giga : '0'
      };
    }
  `,
);

// TODO: change border-radius when the new Circuit bigger options get released
const OverviewWrapper = styled('div')<{
  usedInModal: boolean;
  noMargin: boolean;
}>(
  ({ theme, usedInModal, noMargin = false }) => css`
    background-color: ${!usedInModal && `var(--cui-bg-subtle)`};
    border-radius: ${theme.borderRadius.byte};
    padding: ${!usedInModal && `${theme.spacings.mega}`};
    margin-top: ${theme.spacings.giga};
    ${!usedInModal && `margin-bottom: ${theme.spacings.zetta};`}
    ${noMargin && `margin: 0;`}
  `,
);

const StyledProductList = styled.ul`
  max-height: 350px;
  overflow-y: auto;
`;

const InstallmentContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

const Intallments = styled(Body)(
  ({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
  `,
);

const VatRateLine = ({
  shippingAddress,
  taxRate,
  defaultTaxRate,
  usedForCart,
  taxRateText,
  formattedTotalTaxAmount,
  totalAmountFloat,
  locale,
  doFallbackToDefaultTaxRate,
}) => {
  // If address is attached, use the order tax rate,
  // calcualated by CommerceLayer.
  if (taxRate > 0) {
    return (
      <DescriptionContainer usedForCart={usedForCart}>
        <Body>{`${taxRateText} ${getPercentageTaxRate(taxRate)}%`}</Body>
        <span>{formattedTotalTaxAmount}</span>
      </DescriptionContainer>
    );
  }

  // If no address is attached, use the default tax rate
  // to render vat.
  // Background: CommerceLayer is calculating the correct taxes
  // for an order once an address is attached to the order.
  // We still want to show the default tax rate before this calculation happens.
  if (
    defaultTaxRate > 0 &&
    !shippingAddress?.id &&
    doFallbackToDefaultTaxRate
  ) {
    return (
      <DescriptionContainer usedForCart={usedForCart}>
        <Body>{`${taxRateText} ${getPercentageTaxRate(defaultTaxRate)}%`}</Body>
        <span>
          {getTaxAmountFormatted(defaultTaxRate, totalAmountFloat, locale)}
        </span>
      </DescriptionContainer>
    );
  }

  return null;
};

/**
 * The OrderOverview is the component responsible to present
 * an overview from user's order. It is mainly used inside
 * Cart component and Signup/Login Page.
 */
export const OrderOverview: FC<OrderOverviewProps> = ({
  quantityText,
  subtotalText,
  taxRateText,
  deliveryEstimateText,
  overviewTitleText,
  totalText,
  discountAppliedText,
  freeText,
  formattedSubtotalAmount,
  taxRate = 0,
  defaultTaxRate = 0,
  formattedTotalAmountWithTaxes,
  formattedDiscountAmount,
  discountAmountCents,
  products,
  usedInModal = false,
  usedForCart: usedForCartFromProps = false,
  isQuantitySelectionEnabled: isQuantitySelectionEnabledFromProps,
  largestInstallmentInCart = 1,
  installmentsOnCheckout,
  totalAmountFloat,
  noMargin = false,
  imgHydration = 'lazy',
  canEmptyCart = false,
  onLineItemQuantityChange = () => (): void => {},
  hasBusinessAccount = false,
  formattedTotalTaxAmount,
}: OrderOverviewProps) => {
  const { details: promotionDetails } = useTypedSelector(
    (state) => state.promotions,
  );
  const shippingAddress = useTypedSelector((s) => s.addresses.shippingAddress);
  const source = useShopExperience();
  const { locale, query } = useRouter();

  // TODO for now only apply cart style changes only for the dashboard shop
  const usedForCart =
    usedForCartFromProps && source === ShopExperience.DASHBOARD;
  const isQuantitySelectionEnabled =
    isQuantitySelectionEnabledFromProps ?? source === ShopExperience.DASHBOARD;
  const installments = installmentsOnCheckout ?? largestInstallmentInCart;
  const hasInstallments = installments > 1;
  const formattedInstallmentAmount = getFormattedInstallmentAmount(
    totalAmountFloat,
    installments,
    locale,
  );

  const isExpressCheckoutExperimentEnabled =
    query[SHOW_VAT_AMOUNT_EXPERIMENT.id] === VARIATION ||
    getItem(SHOW_VAT_AMOUNT_EXPERIMENT.id) === VARIATION;

  const totalAmountFormatted = getOrderTotalAmountFormatted({
    shippingAddress,
    defaultTaxRate,
    totalAmountFloat,
    formattedTotalAmountWithTaxes,
    locale,
    doFallbackToDefaultTaxRate: isExpressCheckoutExperimentEnabled,
  });

  return (
    <OverviewWrapper usedInModal={usedInModal} noMargin={noMargin}>
      {overviewTitleText && (
        <Headline size="four" as="h2" css={spacing({ bottom: 'giga' })}>
          {overviewTitleText}
        </Headline>
      )}

      {formattedTotalAmountWithTaxes ? (
        <>
          {!!products?.length && (
            <StyledProductList>
              {products.map((product) => (
                <StyledProductListItem
                  {...product}
                  key={product.id}
                  numberOfInstallments={installments}
                  formattedInstallmentAmount={formattedInstallmentAmount}
                  quantityText={quantityText}
                  usedForCart={usedForCart}
                  isQuantitySelectionEnabled={isQuantitySelectionEnabled}
                  quantityLimit={getPromotionQuantityLimitBySku(
                    product.sku,
                    promotionDetails,
                  )}
                  onChangeQuantity={onLineItemQuantityChange(product)}
                  imgHydration={imgHydration}
                  canEmptyCart={canEmptyCart}
                  numberOfProducts={products.length}
                />
              ))}
            </StyledProductList>
          )}

          {!usedForCart && <StyledHr />}

          {subtotalText && (
            <DescriptionContainer
              usedForCart={usedForCart}
              data-testid="ORDER_OVERVIEW.SUBTOTAL"
            >
              <Body>{subtotalText}</Body>
              <span>{formattedSubtotalAmount}</span>
            </DescriptionContainer>
          )}

          <DescriptionContainer alignItems="start" usedForCart={usedForCart}>
            <Body>
              {deliveryEstimateText &&
                documentToReactComponents(
                  deliveryEstimateText,
                  deliveryEstimateOptions,
                )}
            </Body>
            <FreeDeliveryText>{freeText}</FreeDeliveryText>
          </DescriptionContainer>
          {hasBusinessAccount && (
            <DescriptionContainer alignItems="start" usedForCart={usedForCart}>
              <Body>Business Account (with fee discount)</Body>
              <FreeDeliveryText>FREE</FreeDeliveryText>
            </DescriptionContainer>
          )}
          {!!discountAmountCents && (
            <DescriptionContainer
              data-testid="ORDER_OVERVIEW.DISCOUNT_WRAPPER"
              usedForCart={usedForCart}
            >
              <Body>{discountAppliedText}</Body>
              <span>{formattedDiscountAmount}</span>
            </DescriptionContainer>
          )}

          <VatRateLine
            shippingAddress={shippingAddress}
            taxRate={taxRate}
            defaultTaxRate={defaultTaxRate}
            usedForCart={usedForCart}
            taxRateText={taxRateText}
            formattedTotalTaxAmount={formattedTotalTaxAmount}
            totalAmountFloat={totalAmountFloat}
            locale={locale}
            doFallbackToDefaultTaxRate={isExpressCheckoutExperimentEnabled}
          />

          <StyledHr />

          <DescriptionContainer usedForCart={usedForCart}>
            <Headline size="four" as="h2" css={spacing({ bottom: 0 })}>
              {totalText}
            </Headline>
            {hasInstallments ? (
              <InstallmentContainer>
                <Intallments
                  size="two"
                  variant="subtle"
                  css={spacing({ right: 'bit' })}
                  as="span"
                >
                  {installments}x
                </Intallments>
                <Headline size="four" as="h2">
                  {formattedInstallmentAmount}
                </Headline>
              </InstallmentContainer>
            ) : (
              <TotalPrice data-testid="ORDER_OVERVIEW.TOTAL">
                {totalAmountFormatted}
              </TotalPrice>
            )}
          </DescriptionContainer>
        </>
      ) : (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      )}
    </OverviewWrapper>
  );
};

OrderOverview.defaultProps = {
  taxRate: 0,
  formattedTotalTaxAmount: '',
  formattedTotalAmount: '',
  formattedSubtotalAmount: '',
};
